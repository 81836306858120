<template>
  <div class="columns-wrap-row">
    <div class="col-3 sm-col-12">
      <div class="core-buying core-buying-member">
        <div class="panel-inr-box">
          <div class="box-top-wrap d-flex align-center justify-space-between">
            <div class="item-row">
              <h3 class="box-title green-text"> {{ $t('coreBuying.filter') }}</h3>
            </div>
          </div>
          <div class="custom-card inside-model-box">
            <div class="custom-box">
              <div class="custom-card" style="box-shadow: none;">
                <div class="custom-box">
                  <v-card-text>
                    <v-row>
                      <v-col primary cols="12" sm="12" md="12">
                        <label class="form-label">{{ $t('coreBuying.searchParts') }}</label>
                        <v-autocomplete v-model="searchByParts" :items="partsArrayList"
                          :label="$t('coreBuying.searchParts')" :value="searchByParts" item-text="Description"
                          item-value="Description"  clearable multiple deletable-chips small-chips hide-selected
                          solo dense
                          hide-no-data hide-details :menu-props="{
                            closeOnContentClick: true,
                          }">
                        </v-autocomplete>
                      </v-col>
                      <v-col primary cols="12" sm="12" md="12">
                        <label class="form-label">{{ $t('coreBuying.searchCoreBuying') }}</label>
                        <v-autocomplete v-model="searchByCoreBuyingUsers" :items="coreBuyingUsersList.data"
                          :label="$t('coreBuying.searchCoreBuying')" :value="searchByCoreBuyingUsers"
                          item-text="company_name" item-value="id"  clearable multiple deletable-chips
                          solo dense
                          small-chips hide-selected hide-no-data hide-details :menu-props="{
                            closeOnContentClick: true,
                          }">
                        </v-autocomplete>
                      </v-col>
                      <v-col primary cols="12" sm="12">
                        <v-btn :loading="loading" hide-details="auto" :disabled="loading"
                          color="green darkan-4 green-gradient white--text" class="btn customBtn pattern-btn text-capitalize btn-submit mt-1 font-16"
                          @click="fetchCoreBuyingList">
                         <span> {{ $t("common.submit") }}</span>
                          <template v-slot:loading>
                            <v-icon>mdi-loading</v-icon>
                          </template>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-9 sm-col-12">
      <div class="core-buying core-buying-member">
        <div class="panel-inr-box">
          <div class="box-top-wrap d-flex align-center justify-space-between">
            <div class="item-row">
              <h3 class="box-title green-text"> {{ $t("navbar.coreBuying") }}</h3>
            </div>
          </div>
          <div class="custom-card inside-model-box">
            <div class="custom-box">
              <div class="custom-card" style="box-shadow: none;">
                <div class="custom-box">
                  <v-data-table class="color-light-green core_buyer_table" :header-props="{ sortIcon: null }"
                    :headers="headers" :items="coreBuyingSearchList.data" :single-expand="singleExpand"
                    :expanded.sync="expanded" fixed-header :disable-sort="true" :show-expand="false" :loading="loading"
                    :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
                    hide-default-footer>
                    <template v-slot:item.created_at="{ item }">
                      {{ item.created_at | MMDDYYdatefilter }}
                    </template>
                    <template v-slot:item.company_name="{ item }">
                      {{ item?.user?.user_detail?.company_name }}
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon style="cursor: pointer;" color="primary" dark v-bind="attrs"
                            v-on="on">mdi-information-outline</v-icon>
                        </template>
                        <span>
                          <span v-if="item?.user?.user_detail?.company_email"><b>{{ $t("membersDirectory.email") }}:</b> {{
                            item?.user?.user_detail?.company_email
                          }}<br></span>
                          <span v-if="item?.user?.user_detail?.company_phone"><b>{{ $t("membersDirectory.phone") }}:</b> {{
                            item?.user?.user_detail?.company_phone |
                            USFormatNumber }}<br></span>
                          <span v-if="item?.user?.user_detail?.website"><b>{{ $t("membersDirectory.website") }}:</b> {{
                            item?.user?.user_detail?.website
                          }}<br></span>
                          <span v-if="item?.user?.user_detail?.address"><b>{{ $t("membersDirectory.address") }}:</b> {{
                            item?.user?.user_detail?.address
                          }}<br></span>
                        </span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item.company_phone="{ item }">
                      {{ item?.user?.user_detail?.company_phone | USFormatNumber }}
                    </template>
                    <template v-slot:item.name="{ item }">
                      {{ item.name }}
                    </template>
                    <template v-slot:item.parts="{ item }">
                      <v-chip-group>
                        <v-chip v-for="(part, index) in item.parts" :key="index">
                          {{ part.Description }}
                        </v-chip>
                      </v-chip-group>
                    </template>

                    <template v-slot:item.action="{ item }">

                      <div class="action-btn-row" v-if="item?.media">
                        <a class="action-btn mr-2" @click="viewFile(item)">
                          <v-icon>mdi-eye</v-icon>
                        </a>
                        <a class="action-btn" @click="downloadFile(item)">
                          <v-icon>mdi-download</v-icon>
                        </a>
                      </div>
                    </template>
                  </v-data-table>
                  <div class="text-center custom-pagination">
                    <div class="d-flex justify-space-between align-center">
                      <div class="text-left">
                        <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
                      </div>
                      <div class="text-right">
                        <button @click="previousPage" :disabled="currentPage === 1">
                          {{ $t("pagination.previous") }}
                        </button>
                        <span class="page-number">{{ currentPage }}</span>
                        <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                          {{ $t("pagination.next") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import ConfirmationDialog from "../components/common/ConfirmationDialog";
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";

// Janus Related files

const userData = JSON.parse(localStorage.getItem("user_data"));
let is_sip = userData?.is_sip; // Use optional chaining to prevent errors if userData is null

(async () => {
  if (is_sip === 1) {
    const module = await import("@/plugins/audiobridge_sip");
  } else {
    const module = await import("@/plugins/audiobridge");
  }
})();     


export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    ConfirmationDialog,
    GooglePlace: () => import("@/components/common/GooglePlace"),
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",

      panel: [0],
      saving: false,
      error: "",
      loading: false,
      searchByParts: [],
      searchByCoreBuyingUsers: [],
      singleExpand: true,
      expanded: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      user: "user/getUser",
      coreBuyingSearchList: "coreBuying/getCoreBuyingSearch",
      coreBuyingUsersList: "coreBuying/getCoreBuyingUsers",
      partsArrayList: "coreBuying/getCoreBuyingParts",
    }),
    headers() {
      return [
        {
          text: this.$t("coreBuying.date"),
          align: "left",
          value: "created_at",
          width: "10%",
        },
        {
          text: this.$t("coreBuying.companyName"),
          align: "left",
          value: "company_name",
          width: "20%",
        },
        {
          text: this.$t("coreBuying.companyPhone"),
          align: "left",
          value: "company_phone",
          width: "15%",
        },
        {
          text: this.$t("coreBuying.titleList"),
          align: "left",
          value: "title",
          width: "18%",
        },
        {
          text: this.$t("coreBuying.parts"),
          align: "left",
          value: "parts",
          width: "32%",
        },
        {
          text: this.$t("coreBuying.action"),
          align: "left",
          value: "action",
          width: "15%",
        },
      ];
    },
  },
  async mounted() {
    this.fetchParts();
    this.fetchCoreBuyingUsers();
    this.fetchCoreBuyingList();
  },
  methods: {
    ...mapActions({
      getCoreBuyingSearch: "coreBuying/getCoreBuyingSearch",
      getCoreBuyingUsers: "coreBuying/getCoreBuyingUsers",
      getCoreBuyingParts: "coreBuying/getCoreBuyingParts",
    }),

    async fetchParts() {
      this.loading = true;
      try {
        await this.getCoreBuyingParts();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async fetchCoreBuyingUsers() {
      this.loading = true;
      try {
        await this.getCoreBuyingUsers({});
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchCoreBuyingList(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchCoreBuyingList(); // Fetch new data based on the current page
      }
    },

    async fetchCoreBuyingList() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getCoreBuyingSearch({
          search: this.searchText,
          page: this.currentPage,
          items_per_page: this.perPage,
          parts: this.searchByParts,
          users: this.searchByCoreBuyingUsers,
        });

        if (this.coreBuyingSearchList.meta) {
          this.totalEntries = this.coreBuyingSearchList.meta.total;
          this.itemsPerPage = parseInt(this.coreBuyingSearchList.meta.per_page);
          this.pageCount = this.coreBuyingSearchList.meta.last_page;
        } else {
          this.itemsPerPage = this.coreBuyingSearchList.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async viewFile(item) {
      if (item?.media) {
        console.log(process.env.VUE_APP_MEDIA_UPLOAD_SERVER + 'public/redline/document/' + item.media.id + '/core-buying-file.xls');
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + process.env.VUE_APP_MEDIA_UPLOAD_SERVER + 'public/redline/document/' + item.media.id + '/core-buying-file.xls', '_blank');
      }
    },
    async downloadFile(item) {
      if (item?.media) {
        window.open(process.env.VUE_APP_MEDIA_UPLOAD_SERVER + 'public/redline/document/' + item.media.id + '/core-buying-file.xls', '_blank');
      }
    },
    getPartsJoin(parts) {
      if (parts && parts.length > 0) {
        return parts.map(part => part.Description).join(', ');
      }
    }
  },
};
</script>